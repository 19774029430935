import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  useParams,
} from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { auth } from "../../firebase/firebase.utils";
import { selectCartHidden } from "../../redux/cart/cart.selectors";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { ReactComponent as Logo } from "../../assets/icons/heartning.svg";
import "./header.styles.scss";
import { ReactComponent as AustinIllustration } from "../../assets/austin-illustration.svg";
import { ReactComponent as HearteningLogo } from "../../assets/heartening-logo.svg";
import { ReactComponent as GivingIcon } from "../../assets/ui-icons/giving.svg";
import { ReactComponent as NeedingIcon } from "../../assets/ui-icons/needing.svg";
import { ReactComponent as MailIcon } from "../../assets/ui-icons/mail.svg";
import { ReactComponent as InstagramIcon } from "../../assets/ui-icons/instagram.svg";
import { ReactComponent as AddACauseIcon } from "../../assets/ui-icons/addacause.svg";
// destructure the current user property that we're passing in from our app
class Header extends React.Component {
  constructor(props) {
    super();
    this.state = {
      // Takes active tab from props if it is defined there
      // activeKey: props.activeKey
      // to the element below, add ---> activeKey={this.state.activeKey}
    };

    // Bind the handleSelect function already here (not in the render function)
    //this.handleSelect = this.handleSelect.bind(this);
    // to the element below, add --->  activeStyle={{fontWeight: "bold", color: "red"}}
  }

  render() {
    return (
      <Container fluid className="header">
        <div className="sky-background"></div>

        <a href="https://www.heartening.org/">
          <div className="heartening-logo-stamp">
            <HearteningLogo
              className="heartening-logo"
              width="30px"
              height="30px"
            ></HearteningLogo>
            <p className="logo-url-text d-none d-md-block">heartening.org</p>
          </div>
        </a>

        <a href="https://www.heartening.org/">
          <div className="heartening-logo-stamp">
            <div className="heartening-logo-container">
              <HearteningLogo
                className="heartening-logo"
                width="48px"
                height="48px"
              ></HearteningLogo>
            </div>
            <p className="logo-url-text d-none d-md-block">heartening</p>
          </div>
        </a>

        {/* BOOK A PICK UP CTA */}
        <div className="book-a-pick-up">
          <a
            className="book-a-pick-up-link"
            href="https://calendly.com/heartening-donations/donation-pickup"
            target="_blank"
          >
            book a pick-up
          </a>
        </div>

        {/* BOOK A PICK UP CTA */}
        <div className="book-a-drop-off">
          <a
            className="book-a-drop-off-link"
            href="https://calendly.com/heartening-donations/donation-drop-off-at-the-donation-sorting-room"
            target="_blank"
          >
            book a donation drop-off
          </a>
        </div>

        {/* SMALL ICON EXTERNAL LINKS */}
        <div className="instagram-blob">
          <a
            className="instagram-link"
            target="_blank"
            href="https://www.instagram.com/hearteningaustin/"
          >
            <InstagramIcon
              className="instagram-icon"
              width="28px"
            ></InstagramIcon>
          </a>
        </div>

        <div className="addacause-blob">
          <a
            className="addacause-link"
            target="_blank"
            href="https://forms.gle/CWxjJBpqxkNTujiN7"
          >
            <AddACauseIcon
              className="addacause-icon"
              width="30px"
            ></AddACauseIcon>
          </a>
        </div>

        <div className="about-me-blob">
          <a
            className="about-me-link"
            target="_blank"
            href="https://heartening.org/about"
          >
            <span className="about-me-label">about</span>
          </a>
        </div>

        <div className="thick-ribbon">
          <div className="header-center-anchor">
            <p className="city-statement d-none d-md-block">
              Finding where to give items in
              <span className="selected-city">Austin, TX</span>
            </p>
            <div className="nav-container d-none d-md-block">
              <Nav
                className="pages-nav-pills"
                variant="pills"
                defaultActiveKey="/giving"
              >
                <Nav.Item>
                  <Nav.Link as={NavLink} to="/" exact={true}>
                    <GivingIcon
                      className="giving-icon page-nav-icon"
                      width="27px"
                    ></GivingIcon>
                    items
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={NavLink} to="/needing">
                    <NeedingIcon
                      className="needing-icon page-nav-icon"
                      width="26px"
                    ></NeedingIcon>
                    causes
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Switch>
              <Route path="/:id" />
            </Switch>
          </div>
        </div>
        <AustinIllustration className="city-illustration" />
        <p className="mobile-city-statement d-md-none">
          Finding where to give items in
          <span className="selected-city">Austin, TX</span>
        </p>
        <div className="d-block d-md-none">
          <div className="nav-container">
            <Nav
              className="pages-nav-pills"
              variant="pills"
              defaultActiveKey="/giving"
            >
              <Nav.Item>
                <Nav.Link as={NavLink} to="/" exact={true}>
                  <GivingIcon
                    className="giving-icon page-nav-icon"
                    width="27px"
                  ></GivingIcon>
                  items
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={NavLink} to="/needing">
                  <NeedingIcon
                    className="needing-icon page-nav-icon"
                    width="26px"
                  ></NeedingIcon>
                  causes
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
      </Container>
    );
  }

  handleSelect(selectedKey) {
    // The active tab must be set into the state so that
    // the Tabs component knows about the change and re-renders.
    this.setState({
      activeKey: selectedKey,
    });
  }
}
// this will return an object with the value we want
// state is the top level root reducer
// we want the rootreducer: then the user reducer: then the currentUser value
// the nested destructuring syntax means 'take the currentUser value off of the user value off of the state'
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  hidden: selectCartHidden,
});

// the first argument is the function that allows us to access the state, with the state being our rootreducer
export default connect(mapStateToProps)(Header);
